<template>
    <v-row no-gutters>
        <v-col cols="6"
               sm="4"
               md="3"
               lg="2"
               v-for="(row, i) in favorite_datas"
               :key="i"
        >
            <FavoriteCard :row="row"
                          @childs-event="showDialog"
                          @delete-event="favoriteInfo"
            />
        </v-col>

        <v-dialog v-model="gift_dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  style="z-index: 9999"
        >
            <GiftDetailModal @hide="hideDialog" :item.sync="gift_detail_item">
            </GiftDetailModal>
        </v-dialog>

    </v-row>
</template>

<script>
    import axios from '../../service/axios'

    export default {
        name: 'Favorite',
        components: {
            FavoriteCard: () => import("@/components/card/FavoriteCard"),
            GiftDetailModal: () => import("@/components/modal/GiftDetailModal"),
        },
        data(){
            return {
                favorite_datas:[],
                gift_dialog: false,
                gift_detail_item: null,
            }
        },
        created () {
            if(this.$cookies.get('token') != null){
                this.favoriteInfo();
            }
        },
        methods: {
            showDialog(item){
                this.gift_detail_item = {
                    _id: item.product_id,
                    favorite: true,
                    favorite_id: item._id
                };
                this.gift_dialog = true;
            },
            hideDialog(update_yn){
                this.gift_detail_item = null;
                if(update_yn == true){
                    this.favoriteInfo();
                }
                this.gift_dialog = false;
            },
            favoriteInfo(){
                const params = {
                    accessKey: this.$cookies.get('token'),
                }
                axios.post('/api/product/favoriteList', params).then(res =>{
                    this.favorite_datas = res.data.data;
                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },
        },
    }

</script>

<style lang="scss">
    .preview_img{
        .v-image__image--cover {
            background-size: contain;
        }
    }
    .preview{
        .v-btn--icon.v-size--small{
            width: 10px;
            height: 10px;
        }
    }
    .subheader{
        font-size: 0.85rem;
        align-self: center;
    }
</style>